import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {StepRow} from "../../components/table";
import {ApiLink} from "../../components/link";
import {CodeSnippet} from "../../components/snippet";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Execute SQL Command";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p> To execute an SQL command you should do the following:</p>

        <table className="mb-3">
            <tbody>
            <StepRow name="Step 1." href="#create_command">Initialize SQL command text</StepRow>
            <StepRow name="Step 2." href="#bind_input_params">Bind input parameters</StepRow>
            <StepRow name="Step 3." href="#execute_command">Execute the command</StepRow>
            <StepRow name="Step 4." href="#process_output">Process output</StepRow>
            </tbody>
        </table>

        <p>
            In this section we are going to consider commands like INSERT,
            UPDATE and DELETE that do not involve fetching data. To execute a SELECT command
            or a result set generating stored procedures see {' '}<Link to="/HowTo/fetch/">Fetch result
            set(s)</Link>{' '}
            and {' '}<Link to="/HowTo/procedures/">Call stored procedure</Link>{' '} tutorials.
        </p>
        <p>
            In the following example we assume that the table
            {' '}<code>EMPLOYEES(NAME CHAR(25), AGE INTEGER)</code>{' '} exists in the database our
            connection object is connected to.
        </p>

        <KeyHeader id="create_command">Initialize SQL Command Text</KeyHeader>
        <p>
            To execute a command we need two objects:
            {' '}<ApiLink>SAConnection</ApiLink>{' '}
            (connection object) and
            {' '}<ApiLink>SACommand</ApiLink>{' '} (command object):
        </p>
        <CodeSnippet>{`
SACommand insert(
    &con,
     _TSA("INSERT INTO EMPLOYEES (NAME, AGE) VALUES (:1, :2))");
            `.trim()}
        </CodeSnippet>

        <p>
            The code above creates a command object {' '}<code>insert</code>{' '} based on
            previously created and connected connection object {' '}<code>con</code>{' '}
            (for initializing {' '}<code>con</code>{' '} object see
            {' '}<Link to="/HowTo/connect/">Connect to database</Link>).
            Second parameter is the command text we want to execute.
            The command text can also be provided using
            {' '}<ApiLink>SACommand::setCommandText</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`
SACommand insert(&con);
insert.setCommandText(_TSA("INSERT INTO EMPLOYEES (NAME, AGE) VALUES (:1, :2))");
`.trim()}
        </CodeSnippet>

        <h2 className="mt-5" id="bind_input_params">Bind Input Parameters</h2>
        <p>
            Binding some values to the command is like filling in the
            gaps in the SQL command statement (marked by
            {' '}<code>:1</code>, {' '}<code>:2</code>, {' '}<code>:3</code>, ...)
            with actual data values. The data values can be constants or program
            variables of appropriate types. To associate a command object with
            these bind variables you can use the
            {' '}<ApiLink>{`SACommand::operator <<`}</ApiLink>{' '}
            as shown below:
        </p>
        <CodeSnippet>{`
insert << _TSA("Tom Patt") << 30;
            `.trim()}
        </CodeSnippet>

        <p>
            The order that you associate the values is important (i.e. the
            first {' '}<code>{`<<`}</code>{' '} associates a bind with {' '}<code>:1</code>{' '}, the second
            {' '}<code>{`<<`}</code>{' '} with {' '}<code>:2</code>).
        </p>
        <p>
            Another way to bind input variables is directly assigning
            a value with {' '}<ApiLink>SAParam</ApiLink>{' '}
            object which represents a command parameter. To get appropriated
            parameter object by its position in SQL statement use
            {' '}<ApiLink>SACommand::Param</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`
insert.Param(1).setAsString() = _TSA("Tom Patt");
insert.Param(2).setAsInt32() = 30;
                `.trim()}
        </CodeSnippet>

        <p>
            For more details about binding input variables see in
            {' '}<Link to="/HowTo/bind/">Bind input parameters</Link>{' '} section.
        </p>

        <h2 className="mt-5" id="execute_command">Execute the Command</h2>
        <p>
            Finally we execute the command, which results in the string
            being inserted into column {' '}<code>NAME</code>, and the number being
            inserted into column {' '}<code>AGE</code>:
        </p>
        <CodeSnippet>{`insert.Execute();`}</CodeSnippet>

        <p>
            If you want to see the number of rows affected by the command
            execution use {' '}<ApiLink>SACommand::RowsAffected</ApiLink>{' '}
            method:
        </p>
        <CodeSnippet>{`int nRows = insert.RowsAffected();`}</CodeSnippet>

        <p>
            If you want to insert another row of new data, you just have
            to associate the new bind values with the object and repeat the
            bind/execute part again:
        </p>
        <CodeSnippet>{`
insert << _TSA("Nick Barry") << 35;
insert.Execute();
            `.trim()}
        </CodeSnippet>

        <h2 className="mt-5" id="process_output">Process Output</h2>
        <p>
            Processing output parameters and result set is not actual for
            INSERT, UPDATE and DELETE commands, that's why we discuss this
            questions in other tutorials:
        </p>
        <ul>
            <li>
                For processing result set (if any) see
                {' '}<Link to="/HowTo/fetch/">Fetch result set(s)</Link>
            </li>
            <li>
                For processing output parameters (if any) see
                {' '}<Link to="/HowTo/return_param/">Get output parameters</Link>
            </li>
        </ul>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
